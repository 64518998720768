import { useState, useRef, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import ImageMagnifier from "@/common/ImageMagnifier"
import { UploadedImage } from "@/common/Image"
import { pullToFirst } from "@/services/utils/common"

export const pullFrontToFirst = pullToFirst(
  (item) => item.filename === "front.png"
)

const Gallery = ({ detail, className }) => {
  const resources = pullFrontToFirst(detail.resources)
  const [isBeginning, setIsBeginning] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [currentImage, setCurrentImage] = useState(resources[0])
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const gallery = resources

  useEffect(() => {
    setCurrentImage(resources[0])
  }, [detail])

  return (
    <div className={className}>
      <div className="w-full mb-2.5  tablet:border tablet:border-#707070">
        <ImageMagnifier
          src={currentImage?.uri}
          magnifierHeight={140}
          magnifieWidth={140}
          width={"100%"}
          height={"100%"}
        ></ImageMagnifier>
      </div>
      <div className="flex gap-1 px-[35px] tablet:px-0">
        {gallery?.length > 4 ? (
          <button
            ref={prevRef}
            className={`flex justify-center items-center min-w-[20px] h-auto tablet:min-w-[25px] ${
              isBeginning ? "bg-[#d3d3d3]" : "bg-gray-100"
            }`}
          >
            <div className="pl-1">
              <img
                className={`rotate-[90deg]`}
                src="/icon/arrow-down.svg"
                alt="arrow"
                width={12}
                height={12}
              />
            </div>
          </button>
        ) : null}
        <div className="grow w-[calc(100%-40px)] tablet:w-[calc(100%-58px)]">
          <Swiper
            modules={[Navigation]}
            spaceBetween={5}
            slidesPerView={5}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = prevRef.current
                swiper.params.navigation.nextEl = nextRef.current
                swiper.navigation.init()
                swiper.navigation.update()
                setIsBeginning(swiper.isBeginning)
                setIsEnd(swiper.isEnd)
              })
            }}
            onSlideChange={(swiper) => {
              setIsBeginning(swiper.isBeginning)
              setIsEnd(swiper.isEnd)
            }}
          >
            {gallery.map((image, index) => (
              <SwiperSlide key={image + index}>
                <div className="flex justify-center cursor-pointer">
                  <UploadedImage
                    className="object-cover object-center"
                    src={image.uri}
                    alt=""
                    onClick={() => setCurrentImage(image)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {gallery?.length > 4 ? (
          <button
            ref={nextRef}
            className={`flext justify-center items-center min-w-[20px] h-auto tablet:min-w-[25px] ${
              isEnd ? "bg-[#d3d3d3]" : "bg-gray-100"
            }`}
          >
            <div className={`flex items-center justify-center pr-[3px]`}>
              <img
                className="rotate-[270deg]"
                src="/icon/arrow-down.svg"
                alt="arrow"
                width={12}
                height={12}
              />
            </div>
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default Gallery
