import { findCountry } from "@/hooks/useCountries"
import { useCurrency } from "@/hooks/useCurrency"
import { useMultilang } from "@/hooks/useMultilang"
import { base64Encode, removeDecimal } from "@/services/utils/common"
import Link from "next/link"
import { UploadedImage } from "../common/Image"
import { useMembershipData } from "@/hooks/useMembership"
import { classNames } from "@/services/utils"

const ReservedStatus = ({ reserved, className }) => {
  const { t } = useMultilang()

  return reserved ? (
    <p
      className={classNames(
        "bg-[#09340b] uppercase text-white text-center text-xs p-2",
        className
      )}
    >
      {t("btn.reserved")}
    </p>
  ) : null
}

function TruncatedSummary({ children, className }) {
  return <span className={classNames("truncate", className)}>{children}</span>
}

export const SellerSummary = ({ seller, level, className = "" }) => {
  const { t, multilang } = useMultilang()
  const country = findCountry(multilang)(seller?.region)
  const isMerchant = (level || "").match(/MERCHANT/i)
  const sellerIcon = isMerchant ? "/icon/store.svg" : "/icon/seller.svg"
  const membershipData = useMembershipData(level)
  const userRole = isMerchant
    ? t("profile.subscription.level.merchant")
    : t("profile.subscription.level.member")

  let rating = t("rating.no-rating")
  if (seller?.ratings) {
    const translation =
      seller.ratings.total > 1
        ? "order.buyer.rate-seller.total-ratings"
        : "order.buyer.rate-seller.total-rating"
    rating = `${removeDecimal(seller.ratings.rating)} ${t(translation, {
      total: seller.ratings.total,
    })}`
  }

  const username = seller?.businessName || seller?.displayName

  return (
    <div className={`mt-5 ${className} text-gray-700`}>
      <div className="mb-1">
        <a
          href={`/seller/${base64Encode(seller?.id)}`}
          className="text-ochre-200 flex text-center items-center"
        >
          <img
            className="mr-1 inline-block"
            src={sellerIcon}
            width={15}
            height={15}
            alt=""
          />
          <TruncatedSummary className="hover:underline">
            {username}
          </TruncatedSummary>
        </a>
      </div>
      <div className="mb-1 flex text-center items-center">
        {membershipData ? (
          <img
            className="mr-1 inline-block"
            src={membershipData?.iconUrl}
            width={15}
            height={15}
            alt=""
          />
        ) : null}
        <TruncatedSummary>
          {membershipData?.displayName} {userRole}
        </TruncatedSummary>
      </div>
      <div className="mb-1 flex text-center items-center">
        <img
          className="mr-1 inline-block"
          src="/icon/rating/rating-icon-filled.svg"
          width={15}
          height={15}
          alt=""
        />
        <span>{rating}</span>
      </div>
      <div className="mb-1 flex text-center items-center">
        <img
          className="mr-1 inline-block"
          src="/icon/region.svg"
          width={15}
          height={15}
          alt=""
        />
        <TruncatedSummary>{country}</TruncatedSummary>
      </div>
    </div>
  )
}

const WatchSummary = function ({
  id,
  image,
  title,
  price,
  brand,
  seller,
  currency,
  reserved = false,
}) {
  const { price: p } = useCurrency()

  return (
    <div className="w-full h-full max-w-xs min-w-10 bg-white border border-[#f2f2f2] transition-shadow hover:shadow-[0_3px_15px_0_rgba(0,0,0,0.3)] flex flex-col">
      <div className="bg-gray-300">
        <Link href={`/watch/${base64Encode(id)}`}>
          <a>
            <div className="relative w-full pb-[100%] overflow-hidden">
              <UploadedImage
                src={image}
                alt={title}
                className="absolute w-full h-full object-cover object-center"
              />
              <ReservedStatus
                reserved={reserved}
                className="absolute w-full bottom-0"
              />
            </div>
          </a>
        </Link>
      </div>
      <div className={`p-4 grow ${reserved ? "bg-[#f4f4f4]" : ""}`}>
        <p
          href="#"
          className="text-ochre-200 text-sm mb-2 uppercase line-clamp-2 h-10 break-words"
        >
          {brand?.name}
        </p>
        <h5 className="text-sm mb-2.5 h-10 line-clamp-2 break-words">
          <Link href={`/watch/${base64Encode(id)}`}>
            <a>{title}</a>
          </Link>
        </h5>
        <p>
          <strong className={`font-semibold desktop:text-lg`}>
            {p(price, currency)}
          </strong>
        </p>
        <SellerSummary
          seller={seller}
          level={seller.level}
          className="text-xs"
        />
      </div>
    </div>
  )
}

export default WatchSummary
