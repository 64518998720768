import { MEMBERSHIP_LEVELS } from "@/components/Profile/Subscription/utils/constants"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import find from "lodash/find"
import { useMultilang } from "./useMultilang"

const DEFAULT_ICONS = {
  BASIC_SELLER: "/icon/membership/essential.svg",
  LEVEL_2_SELLER: "/icon/membership/luxury.svg",
  BASIC_MERCHANT: "/icon/membership/pro.svg",
  LEVEL_2_MERCHANT: "/icon/membership/premium.svg",
  ELITE: "/icon/membership/elite.svg",
}

export const useMembershipData = (level = MEMBERSHIP_LEVELS.ESSENTIAL) => {
  const { multilang } = useMultilang()
  const allDetails = useSelector((state) => state.user.allMembershipDetails)
  const planDetail = useMemo(() => {
    return find(allDetails, {
      level: level,
    })
  }, [level, allDetails])

  const iconUrl = useMemo(() => {
    if (planDetail?.icon) {
      return planDetail?.icon
    }

    return DEFAULT_ICONS[level]
  }, [planDetail, level])

  return {
    iconUrl,
    displayName: multilang(planDetail, "name"),
    ...planDetail,
  }
}
